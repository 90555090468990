import React from 'react';
import Header from 'screens/TournamentDetails/Register/Header';
import FormLogin from 'components/forms/FormLogin';
import { RegisterProps, Steps } from '../types';

export default function Login({ setSteps, handleNext }: RegisterProps) {
  return (
    <div className="tournament-register-form h-full px-6 py-5">
      <div className="">
        <Header title="Register" cta="Login" />
      </div>
      <div className="mt-2 h-full">
        <FormLogin
          isFormOnly
          isShowSignupLink
          shouldSkipReload
          toggleSignup={() => setSteps(Steps.CreateAccount)}
          formWrapperClassName="pb-8 pt-1 px-1"
          buttonWrapperClassName="px-6 pb-6"
          spacerClassName="space-y-6"
          handleAuthComplete={() => handleNext()}
        />
      </div>
    </div>
  );
}
